<template>
    <div class="fullscreen row">
        <div class="col flex items-center justify-center">
            <div class="q-gutter-sm form-container">
                <h4 class="text-dark-gray text-weight-medium text-center">{{ capital($t('auth.reset_password')) }}</h4>
                <q-form @submit="submit">
                  <q-input
                        color="light-gray"
                        label-color="light-gray"
                        square
                        outlined
                        v-model="formData.password"
                        :label="$t('generals.password')"
                        :type="hidePassword ? 'password' : 'text'"
                        :rules="[
                            val => !!val || $t('validation.required', { field: $t('generals.password')}),
                            val => val.length >= 8 || $t('validation.min', { field: $t('generals.password'), size: 8})
                        ]"
                        lazy-rules
                  >
                      <template v-slot:append>
                          <q-icon
                                :name="hidePassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                class="cursor-pointer"
                                @click="hidePassword = !hidePassword"
                          />
                      </template>
                  </q-input>
                  <q-input
                          color="light-gray"
                          label-color="light-gray"
                          square
                          outlined
                          v-model="formData.password_confirmation"
                          :label="$t('generals.password_confirmation')"
                          :type="hidePassword ? 'password' : 'text'"
                          :rules="[
                              val => !!val || $t('validation.required', { field: $t('generals.password_confirmation')}),
                              val => val.length >= 8 || $t('validation.min', { field: $t('generals.password_confirmation'), size: 8})
                          ]"
                          lazy-rules
                  >
                      <template v-slot:append>
                          <q-icon
                                  :name="hidePasswordConfirm ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                  class="cursor-pointer"
                                  @click="hidePasswordConfirm = !hidePasswordConfirm"
                          />
                      </template>
                  </q-input>
                  <div class="text-center q-mt-md">
                      <q-btn color="primary" class="q-px-lg q-py-xs" no-caps @click="submit">{{ capital($t('auth.save_new_password')) }}</q-btn>
                  </div>
                </q-form>
            </div>
        </div>
    </div>
</template>

<script>
import { capitalize } from 'lodash'
import User from 'src/store/models/User'

export default {
  name: 'PasswordReset',

  data () {
    return {
      hidePassword: true,
      hidePasswordConfirm: true,
      formData: {
        password: '',
        password_confirmation: '',
        token: ''
      }
    }
  },

  methods: {
    submit () {
      this.$q.loading.show()
      if (User.me()) {
        return User.api().put(
          '/api/me',
          this.formData
        )
          .then(({ response }) => {
            this.$q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check',
              message: this.$t('generals.password_updated'),
              position: 'top'
            })
            this.$router.push({ name: 'projects' })
          }).finally(() => {
            this.$q.loading.hide()
          })
      } else {
        User.$resetPassword(this.formData)
          .then((response) => {
            this.$router.push({ name: 'login' })
          })
          .finally(() => {
            this.$q.loading.hide()
          })
      }
    },
    capital (a) {
      return capitalize(a)
    }
  },

  mounted () {
    if (this.$route.query.token) {
      this.formData.token = this.$route.query.token
    }
  }
}
</script>

<style scoped lang="scss">
    .form-container {
        max-height: 100vh;
        overflow: auto;
    }
    .app-name {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    body.screen--xl,body.screen--lg {
        .form-container {
            width: 30%;
        }
    }
</style>
